// import React, { useRef } from "react"
import React from "react"
// import PropTypes from "prop-types"
import styled from "styled-components"
// import { MDXRenderer } from "gatsby-plugin-mdx"
// import Img from "gatsby-image"
// import { motion } from "framer-motion"

// import { useOnScreen } from "../../hooks"
import ContentWrapper from "../../styles/ContentWrapper"

// const StyledSection = styled(motion.section)`
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  // display: flex;
  // justify-content: center;
  position: relative;
  z-index: 5;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    /* Don't stretch container over the full page width */
    // max-width: 25rem;
    height: 100%;
    // display: inline-block;
    margin: 0 auto;
    p {
      margin: 0.75rem 0;
      strong {
        font-weight: normal;
        font-size: 1.75rem;
        line-height: 1.15;
        margin: 0.5rem 0;
      }
    }
    h2 {
      margin-top: 2rem;
    }
  }
`

const Kerngebiete = () => {
  // Required for animation
  // const ref = useRef()
  //   const onScreen = useOnScreen(ref)
  //   const variants = {
  //     hidden: { opacity: 0, y: 20 },
  //     visible: { opacity: 1, y: 0 },

  return (
    <StyledSection id="kerngebiete">
      <StyledContentWrapper>
        <div>
          <h2>Unsere Kerngebiete</h2>
          <p>
            <strong>
              Wir sind die Brückenbauer zwischen strategischen, konzeptionellen
              Aufgaben und kreativer, zeitnahen Umsetzung.
            </strong>
          </p>
          <p>
            <strong>
              Von der externen Analyse Ihrer Interessen zum Konzept.
            </strong>
          </p>
          <p>
            Wir setzen immer beide Brillen auf: Ihre individuellen Bedürfnisse
            und Wünsche in Sachen Interessensvertretung sowie diejenige Ihrer
            Zielgruppe. Dieser Schritt ermöglicht die Entwicklung einer
            konkreten Umsetzungsstrategie.
          </p>
          <p>
            <strong>
              Von der interessensspezifischen Strategie zur Umsetzung.
            </strong>
          </p>

          <h2>
            Informations-
            <br />
            Layering
          </h2>
          <p>
            Jede Zielgruppe hat ihren Informationskonsum: Die Einen suchen die
            wichtigsten Informationen und Statements – den kurzen Überblick. Die
            Anderen wollen mehr Details, sind auf dem Weg zur Vertiefung. Die
            Insider sprechen und verstehen die Spezialistensprache.
          </p>
          <p>
            Jede Zielgruppe soll ihren Informationslayer der Thematik über den
            besten Kanal und zur richtigen Zeit erhalten.
          </p>

          <h2>Micro Campaining</h2>
          <p>
            Zur Umsetzung Ihrer Strategie nutzen wir ein smartes Zusammenspiel
            zwischen neuen Kanälen und Kommunikatoren rund um Social Media sowie
            real erlebbaren Aktionen ausserhalb der digitalen Welt.
          </p>
          <p>
            Packen Sie mit einer attraktiven Nutzung der Geschichten und Bilder
            ihre potentiellen Zielgruppen.
          </p>
          <h2>Netzwerk Auf- und Ausbau</h2>
          <p>
            Durch die enge Zusammenarbeit mit Meinungsmacherinnen/-machern und
            motivierten Aktivistinnen/Aktivisten aus den eigenen Reihen nutzen
            wir Ihre bestehenden Netzwerke und bauen sie durch attraktive
            Inhalte aus. Wo nötig und sinnvoll helfen wir beim Aufbau und
            Unterhalt von sogenannten Grassroot-Kampagnen, unter deren Schirm
            sich Personen und Gruppen ausserhalb Ihres direkten Netzwerkes
            einspannen lassen.
          </p>
          <p>
            Lassen Sie uns politische Meinungen bilden und Ihre Interessen auch
            bei Abstimmungen und Wahlen clever umsetzen.
          </p>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

// Kerngebiete.propTypes = {
//   content: PropTypes.arrayOf(
//     PropTypes.shape({
//       node: PropTypes.shape({
//         body: PropTypes.string.isRequired,
//       }).isRequired,
//     }).isRequired
//   ).isRequired,
// }

export default Kerngebiete
