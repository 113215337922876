// import React, { useRef } from "react"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
// import { motion } from "framer-motion"

// import { useOnScreen } from "../../hooks"
import ContentWrapper from "../../styles/ContentWrapper"

// const StyledSection = styled(motion.section)`
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};

  margin-top: -15rem;
  // display: flex;
  // justify-content: center;
  position: relative;
  z-index: 5;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    /* Don't stretch container over the full page width */
    // max-width: 25rem;
    height: 100%;
    margin: 0 auto;
    // display: inline-block;
    p {
      margin-bottom: 1rem;
    }
  }
`

const Intro = ({ content }) => {
  const { body } = content[0].node

  // Required for animation
  // const ref = useRef()
  //   const onScreen = useOnScreen(ref)
  //   const variants = {
  //     hidden: { opacity: 0, y: 20 },
  //     visible: { opacity: 1, y: 0 },
  //   }

  return (
    <StyledSection id="intro">
      <StyledContentWrapper>
        <MDXRenderer>{body}</MDXRenderer>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Intro.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Intro
