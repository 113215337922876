// import React, { useRef } from "react"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// import { MDXRenderer } from "gatsby-plugin-mdx"
// import Img from "gatsby-image"
// import { motion } from "framer-motion"

// import { useOnScreen } from "../../hooks"
import ContentWrapper from "../../styles/ContentWrapper"

// const StyledSection = styled(motion.section)`
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  // display: flex;
  // justify-content: center;
  position: relative;
  z-index: 5;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    // display: inline-block;
    margin: 0 auto;
    p {
      margin: 1em 0;
    }
    h2,
    h3 {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    h2,
    h3 {
      font-size: 1.5rem;
      line-height: 1.75rem;
      color: ${({ theme }) => theme.colors.tertiary};
    }
    .row {
      .col {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
        &.imgcol {
          padding-left: 0;
          padding-right: 0;
        }
      }
      &.wcols {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        .col {
          padding-left: 0;
          padding-right: 0;
          flex-basis: 50%;
          .inner div {
            padding-bottom: 1rem;
            &.nobotpad {
              padding-bottom: 0;
            }
          }

          &:first-child {
            .inner {
              margin-right: 0.5rem;
              div {
                padding-left: 1rem;
              }
            }
          }
          &:last-child {
            .inner {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }

    .line_arrow {
      padding-bottom: 1rem;
      background-image: url(line_arrow.svg);
      background-repeat: no-repeat;
      background-position: left bottom;
    }
    .line {
      padding-bottom: 1.5rem;
      background-image: url(line_straight.svg);
      background-repeat: no-repeat;
      background-position: left bottom;
    }
    .line_dots {
      padding-bottom: 0.75rem;
      background-image: url(dotline.svg);
      background-repeat: no-repeat;
      background-position: center bottom;
    }
    #review {
      height: 50px;
      float: right;
    }
    .notopborder {
      border-top: 0;
    }
    h2 {
      margin-top: 1rem !important;
      margin-bottom: 0.75rem !important;
    }
    h3 {
      margin-top: 0 !important;
      margin-bottom: 0.75rem;
      &.campaigning {
        margin-top: 1.5rem !important;
      }
    }
    #kommint {
      height: 148px;
      text-align: center;
      img {
        height: 100%;
        width: auto;
      }
    }
    #kampagne {
      margin-top: 2rem;
    }
  }
`

const Angebot = () => {
  return (
    <StyledSection id="angebot">
      <StyledContentWrapper>
        <div>
          <div class="line_arrow">
            <h2 class="line">
              <span></span>
              Picture this —<br />
              Angebot
            </h2>
            <h3>
              Social Media
              <br />
              Development
            </h3>
            <div class="row">
              <div class="col">
                Thematische
                <br />
                Analyse
              </div>
            </div>
          </div>

          <div id="instrumentarium" class="line_arrow bottompadding">
            <div class="row">
              <div class="col">
                Plakatives
                <br />
                argumentatives
                <br />
                Instrumentarium
              </div>
              <div class="col imgcol">
                <img src="instrumentarium.svg" alt="" />
              </div>
            </div>

            <div id="motivserien" class="row wcols">
              <div class="col">
                <div class="inner">
                  <div class="nobotpad">
                    Kreation
                    <br />
                    Motivserien
                  </div>
                </div>
              </div>
              <div class="col">
                <img src="motivserien.svg" alt="" />
              </div>
            </div>
          </div>

          <div id="programmierung" class="row">
            <div class="col">
              Kommunikative
              <br />
              Programmierung
              <br />
              über Zeit
            </div>
            <div class="col imgcol">
              <img src="programmierung.svg" alt="" />
            </div>
          </div>

          <div id="" class="line_arrow">
            <div class="row">
              <div class="col">
                Aktivierung über
                <br />
                Opinion Leader/Politiker
              </div>
              <div class="col imgcol">
                <img src="opinion-leader.svg" alt="" />
              </div>
              <div class="col">
                Tweet and retweet
                <br />
                Thematische Sensibilisierung
              </div>
            </div>
          </div>

          <div class="line_arrow bottompadding">
            <div class="row wcols">
              <div class="col">
                <div class="inner">
                  <div>
                    Argumentative
                    <br />
                    Vertiefung
                  </div>
                  <img src="argumentative-vertiefung.svg" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="inner">
                  <div>
                    Kommunikative
                    <br />
                    Interaktion
                  </div>
                  <div id="kommint">
                    <img src="kommunikative-interaktion.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <h3 class="campaigning">Campaigning</h3>
            <div class="row wcols">
              <div class="col">
                <div class="inner">
                  <div>Happening</div>
                  <img src="happening.svg" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="inner">
                  <div>Kampagne</div>
                  <img id="kampagne" src="kampagne.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="line">
          <div class="row">
            <div class="col">Meinungsbildung</div>
          </div>
        </div>
        <div class="line_dots">
          <div class="row wcols">
            <div class="col">
              <div class="inner">
                <div class="nobotpad">
                  Review
                  <br />
                  Next Steps
                </div>
              </div>
            </div>
            <div class="col">
              <div class="inner">
                <img id="review" src="review.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Angebot.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Angebot
