import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
// import "intersection-observer"
// import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import Sticky from "react-stickynode"
import { motion, useAnimation } from "framer-motion"
import { InView } from "react-intersection-observer"
import Context from "../../context/"
import ContentWrapper from "../../styles/ContentWrapper"
// import Underlining from "../../styles/Underlining"
// import Social from "../social"
import SplashScreen from "../splashScreen"
import { useScroll } from "../../utils"
// import Theme from "../../styles/Theme"

const StyledSection = styled.section`
  width: 100%;
  height: 150vh;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    padding: 0;
    height: 150vh;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    margin-bottom: 6rem;
    max-width: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-bottom: 4rem;
    }
    .circle {
      z-index: 3;
      background-image: url(circle.svg);
    }
    .circlewrapper {
      position: relative;
      height 100vh;
      width: 100%;
      top: 0;
      &>* {
        position: absolute;
        height: 100vh;
        width: 100%;
        background-repeat: no-repeat;
        background-size: auto 130vh;
        background-position: center -30vh;
      }
      // &.unfix {
      //   position: relative;
      //   .herospacer {
      //     height: ${props => props.ypos};
      //   }
      //   .circle {
      //     z-index: 3;
      //     background-image: url(circle.svg);
      //   }
      // }
    }
    
    .txt1 {
      z-index: 2;
      background-image: url(txt1path.svg);
      opacity: 0;
    }
    .txt2 {
      z-index: 1;
      background-image: url(txt2path.svg);
      opacity: 0;
    }
    .wp1 {
      top: 115vh;
    }
    .wp2 {
      top: 150vh;
    }
    .waypt {
      // border-top: 1px solid;
      position: absolute;
      width: 100%;
      height: 1px;
    }
  }
  `

// const AnimatedUnderlining = motion.custom(Underlining)
const variants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.6, ease: "circOut" },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.6, ease: "circOut" },
  },
}

const Hero = () => {
  // const { frontmatter, body } = content[0].node
  const { isIntroDone } = useContext(Context).state

  const [titleVisibility, setTitleVisibility] = useState(true)
  const [textVisibility, setTextVisibility] = useState(false)
  // const [toggleClass, setToggleClass] = useState(false)
  // const [scrollPosition, setScrollPosition] = useState(0)

  let scrollInfo = useScroll()

  // Controls to orchestrate animations of circle, title, text
  const cCtrls = useAnimation()
  const titleCtrls = useAnimation()
  const textCtrls = useAnimation()

  const ivCache1 = React.useRef(false)
  const showHideText = (inView, entry) => {
    console.log("line1 " + inView + " / " + ivCache1.current)
    console.log(scrollInfo)
    if (scrollInfo.scrollDirection === "down") {
      console.log("down")

      if (inView === false && ivCache1.current === true) {
        setTitleVisibility(true)
        setTextVisibility(false)
      } else {
        setTitleVisibility(false)
        setTextVisibility(false)
      }
    } else if (scrollInfo.scrollDirection === "up") {
      console.log("up")
      if (inView === false && ivCache1.current === false) {
        setTitleVisibility(false)
        setTextVisibility(true)
      } else if (inView === true && ivCache1.current === false) {
        setTitleVisibility(false)
        setTextVisibility(true)
      } else {
        setTitleVisibility(false)
        setTextVisibility(false)
      }
    }
    ivCache1.current = inView
  }

  const ivCache2 = React.useRef(false)
  const hideText = (inView, entry) => {
    console.log("line2 " + inView + " / " + ivCache2.current)

    if (scrollInfo.scrollDirection === "down") {
      console.log("down")
      if (inView === false && ivCache2.current === true) {
        setTitleVisibility(false)
        setTextVisibility(true)
      } else {
        setTitleVisibility(false)
        setTextVisibility(false)
      }
    } else if (scrollInfo.scrollDirection === "up") {
      console.log("up")
      if (inView === false && ivCache2.current === true) {
        setTitleVisibility(false)
        setTextVisibility(false)
      } else {
        setTitleVisibility(false)
        setTextVisibility(false)
      }
    }

    ivCache2.current = inView
  }
  // const handleSticky = (inView, entry) => {
  //   if (inView) {
  //     setToggleClass(true)
  //     // handleScroll()
  //   } else {
  //     setToggleClass(false)
  //   }
  // }
  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    if (isIntroDone) {
      cCtrls.start({
        opacity: 1,
        transition: { duration: 1, ease: "circOut" },
      })
      // console.log(scrollDir)
      if (titleVisibility === true) titleCtrls.start(variants.visible)
      else titleCtrls.start(variants.hidden)

      if (textVisibility === true) {
        textCtrls.start(variants.visible)
      } else {
        textCtrls.start(variants.hidden)
      }

      // if (toggleClass === true) {
      // } else {
      // }
    }
  }, [
    isIntroDone,
    cCtrls,
    titleCtrls,
    textCtrls,
    titleVisibility,
    textVisibility,
    // toggleClass,
    // scrollDir,
  ])

  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}
      <StyledContentWrapper>
        <Sticky enabled={true} top={0} bottomBoundary={"#spacer"}>
          {/*<div className={`circlewrapper ${toggleClass ? "unfix" : ""}`}>*/}
          <div className="circlewrapper">
            <motion.div
              className="circle"
              initial={{ opacity: 0 }}
              animate={cCtrls}
            />
            <motion.div
              className="txt1"
              initial={{ opacity: 0 }}
              animate={titleCtrls}
            />
            <motion.div
              className="txt2"
              initial={{ opacity: 0 }}
              animate={textCtrls}
            />
          </div>
        </Sticky>
        <InView as="div" className="waypt wp1" onChange={showHideText}></InView>
        <InView as="div" className="waypt wp2" onChange={hideText}></InView>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
