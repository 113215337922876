import React from "react"
import styled from "styled-components"

import ContentWrapper from "../../styles/ContentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    /* Don't stretch container over the full page width */
    height: 100%;
    display: inline-block;
    height: 560px;
  }
`

const Intro = () => {
  return (
    <StyledSection id="spacer">
      <StyledContentWrapper />
    </StyledSection>
  )
}

export default Intro
