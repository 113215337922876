import React, { useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion } from "framer-motion"

import { useOnScreen } from "../../hooks/"
import ContentWrapper from "../../styles/ContentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .section-title {
      padding: 0.5rem 0;
      margin: 0;
    }
    .section-title,
    h3 {
      font-size: 100%;
      color: white;
    }
    .inner {
      background-color: black;
    }
    .section-title,
    .inner-wrapper {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .inner-wrapper {
      p {
        padding-bottom: 2rem;
        margin-bottom: 0;
        &:first-child {
          padding-bottom: 3rem;
        }
      }
      color: white;
    }
    .text-content {
      width: 100%;
      max-width: 31.25rem;
      a img {
        padding-top: 9px;
      }
    }
    .image-content {
      width: 100%;
      margin-bottom: 1rem;
      margin-left: 0;
    }
  }
`

const About = ({ content }) => {
  const { frontmatter, body } = content[0].node

  // Required for animating the text content
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)
  const tVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  // Required for animating the image
  const iRef = useRef()
  const iOnScreen = useOnScreen(iRef)
  const iVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <StyledSection id="about">
      <StyledContentWrapper>
        <div className="inner">
          <h2 className="section-title">{frontmatter.title}</h2>
          <motion.div
            className="image-content"
            ref={iRef}
            variants={iVariants}
            animate={iOnScreen ? "visible" : "hidden"}
          >
            <Img
              className="about-author"
              fluid={frontmatter.image.childImageSharp.fluid}
            />
          </motion.div>
          <motion.div
            className="inner-wrapper"
            ref={tRef}
            variants={tVariants}
            animate={tOnScreen ? "visible" : "hidden"}
          >
            <div className="text-content">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </motion.div>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

About.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default About
