import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import Intro from "../components/sections/intro"
import Spacer from "../components/sections/spacer"
import Staerke from "../components/sections/staerke"
import Kerngebiete from "../components/sections/kerngebiete"
import Angebot from "../components/sections/angebot"
import About from "../components/sections/about"
import { splashScreen } from "../config"

const IndexPage = ({ data }) => (
  <Layout splashScreen={splashScreen}>
    <SEO title="Picturethis — Social Media Development and Campaigning" />
    <Hero />
    <Intro content={data.intro.edges} />
    <Spacer />
    <Staerke content={data.staerke.edges} />
    <Kerngebiete />
    <Angebot />
    <About content={data.about.edges} />
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  {
    intro: allMdx(filter: { fileAbsolutePath: { regex: "/intro/" } }) {
      edges {
        node {
          body
        }
      }
    }
    staerke: allMdx(filter: { fileAbsolutePath: { regex: "/staerke/" } }) {
      edges {
        node {
          body
        }
      }
    }
    kerngebiete: allMdx(
      filter: { fileAbsolutePath: { regex: "/kerngebiete/" } }
    ) {
      edges {
        node {
          body
        }
      }
    }
    angebot: allMdx(filter: { fileAbsolutePath: { regex: "/angebot/" } }) {
      edges {
        node {
          body
        }
      }
    }
    about: allMdx(filter: { fileAbsolutePath: { regex: "/about/" } }) {
      edges {
        node {
          body
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
